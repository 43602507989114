/* eslint-disable  */
export const appConfig = () => ({
	dataAPI: 'https://invdata.gopuff.tech',
	goScan: '',
	inventoryOps: '',
	packingAPI: 'https://staging-backend.gopuff.com/packing/api',
	backoffice: 'https://staging-backend.gopuff.com/backoffice/api',
	scanningApp: 'https://staging.gopuff.com/scanning_app',
	pickBff: 'https://pick-for-pallet-api.wms.gopuff.tech/query',
	inboundBff: 'https://wms-inbound-bff.wms.gopuff.tech/query',
	pickService: 'https://wms-pick-service.warehouse.gopuff.dev',
	packingService: 'https://packing.wms.gopuff.tech',
	productDetailService: 'https://pds.catalog.gopuff.tech',
	productSearchService: 'https://search.commerce.gopuff.tech',
	locationFlags: '[]'
 })
export const appSettings = () => ({
  editableFields: false,
  showUPCs: false,
  stayLoggedIn: true,
  tokenSignIn: false,
  useMockData: false,
  ignoreGoogleExpiry: false,
  isUsingCanOverrideProp: true,
  printLoginURLToConsole: false,
  useBackofficeToken: true,
  showListCountBadges: true,
  useCollectionView: true,
  showUnusedModes: false,
  'wms-pds-support': false
})
